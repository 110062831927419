// CLIENTS
// const slider = document.querySelector(".items");
const slides = document.querySelectorAll(".item");
const buttonPrev = document.querySelector(".buttonPrev");
const buttonNext = document.querySelector(".buttonNext");

let current = 2;
let prev = current - 1;
let prevprev = current - 2;
let next = current + 1;
let nextnext = current +2;

buttonPrev.addEventListener("click",() => gotoPrev());
buttonNext.addEventListener("click",() => gotoNext());

const gotoPrev = () => current > 0 ? gotoNum(current - 1) : gotoNum(slides.length - 1);

const gotoNext = () => current < slides.length - 1 ? gotoNum(current + 1) : gotoNum(0);

const gotoNum = number => {
    current = number;
    prev = number - 1;
    prevprev = number - 2;
    next = number + 1;
    nextnext = number + 2;

    for (let i = 0; i < slides.length; i++) {
        slides[i].classList.remove("active");
        slides[i].classList.remove("prev");
        slides[i].classList.remove("next");
        slides[i].classList.remove("nextnext");
        slides[i].classList.remove("prevprev");
    }

    if (nextnext == slides.length + 1) {
        nextnext = 1;
    }

    if (nextnext == slides.length) {
        nextnext = 0;
    }

    if (next == slides.length) {
        next = 0;
    }

    if (prev == -1) {
        prev = slides.length - 1;
    }

    if (prevprev == -1) {
        prevprev = slides.length - 1;
    }

    if (prevprev == -2) {
        prevprev = slides.length - 2;
    }

    slides[current].classList.add("active");
    slides[prev].classList.add("prev");
    slides[next].classList.add("next");
    slides[nextnext].classList.add("nextnext");
    slides[prevprev].classList.add("prevprev");
}


//TESTIMONIALS
// const testimonialSlider = document.querySelector('.testimonialItems');
const testimonialSlides = document.querySelectorAll(".testimonialItem");
const testimonialButtonPrev = document.querySelector(".testimonialButtonPrev");
const testimonialButtonNext = document.querySelector(".testimonialButtonNext");

let testimonialCurrent = 0;

testimonialButtonPrev.addEventListener("click",() => testimonialGotoPrev());
testimonialButtonNext.addEventListener("click",() => testimonialGotoNext());

const testimonialGotoPrev = () => testimonialCurrent > 0 ? testimonialGotoNum(testimonialCurrent - 1) : testimonialGotoNum(testimonialSlides.length - 1);

const testimonialGotoNext = () => testimonialCurrent < testimonialSlides.length - 1 ? testimonialGotoNum(testimonialCurrent + 1) : testimonialGotoNum(0);

const testimonialGotoNum = number => {
    testimonialCurrent = number;

    for (let i = 0; i < testimonialSlides.length; i++) {
        testimonialSlides[i].classList.remove("active");
    }

    testimonialSlides[testimonialCurrent].classList.add("active");
}
